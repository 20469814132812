import { useNavigate } from 'react-router-dom';
import { useAlert as alert } from '@hooks/modal/useAlert';
import { useStateChange as stateChange } from '@hooks/modal/useStateChange';
import { LANGUAGE, TypeCategory, isPublished } from '@src/common/config/Code';
import ModalType from '@src/common/config/ModalType';
import { textSlice } from '@src/common/utils/Func';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import TaskNameViewerComp from '@src/pages/components/TaskNameViewer.Comp';
import { IconDetail } from '@src/resources/icon';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { ReactElement, useEffect } from 'react';
import RowStatusBadge2 from '../components/RowStatusBadge2';
import {
  TaskFileButton,
  renderDateTime,
  renderGrossInfo,
  renderNetInfo
} from '../dashboard/utils/render';
import { css } from '@emotion/css';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';

export const projectColumns = (
  projectId: any,
  putStatus: (payload: any) => void,
  deleteTask: (id: string) => void,
  isSort: any,
  setIsSort: any
) => {
  const { openHandle: openAlert } = alert();
  const { openHandle: openStateChange } = stateChange();
  const navigate = useNavigate();

  const { myInfo } = useMyInfo();

  const isPML = myInfo?.roles.includes('PML');
  const isPM = myInfo?.roles.includes('PM');

  return [
    {
      code: 'taskStatus',
      value: '상태',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      // renderRowData: (data: any) => <StatusBadge statusCode={data} />
      renderRowData: (data: any, rowData: any) => (
        <RowStatusBadge2
          style={{
            padding: '0 0 0 12px',
            cursor:
              rowData?.taskStatus === 'COMPLETE'
                ? rowData?.includeSubTaskByPm
                  ? 'default'
                  : 'pointer'
                : 'pointer'
          }}
          id={rowData.taskId}
          status={rowData.taskStatus}
          statusChangeMap={{
            NEW: ['STOP'],
            COMPLETE_ASSIGN: ['STOP'],
            PROGRESS: ['STOP'],
            COMPLETE: ['DELIVERY_COMPLETE'], // BORACAT-697
            // DELIVERY_COMPLETE: []
            STOP: [
              // 'NEW',
              // 'COMPLETE_ASSIGN',
              // 'PROGRESS',
              // 'COMPLETE',
              // 'DELIVERY_COMPLETE'
            ] // 전부 안됨
          }}
        />
      )
    },
    {
      code: 'taskKey',
      value: '키',
      width: 88,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="key"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'taskTitle',
      value: '작업',
      width: isPublished ? 300 : 396,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
            style={{
              justifyContent: 'flex-start'
            }}
          />
        );
      },
      renderRowData: (data: any) => {
        if (data) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                textAlign: 'left',
                width: '100%'
              }}
            >
              {data}
            </div>
          );
        }
      }
    },
    {
      code: 'taskType',
      value: '유형',
      width: 72,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="taskType"
          />
        );
      },
      renderRowData: (data: any) => {
        const typeCategory = (TypeCategory[data] as string) || '';
        return <span>{typeCategory}</span>;
      }
    },
    {
      code: 'startLanguage',
      value: '출발어',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );
        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );
        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    // {
    //   code: 'projectAssignerList',
    //   value: '담당자',
    //   width: 120,
    //   renderHeader: 'string',
    //   renderRowData: (data: any, rowData: any, counter: any, dataList: any) => {
    //     let cell: ReactElement | undefined = undefined;
    //     const list = counter?.projectAssignerList;
    //     if (Array.isArray(list) && list.length > 0) {
    //       cell = <TPAvatarGroupNew userList={list} />;
    //     } else {
    //       if (rowData.taskStatus === 'STOP') return;
    //       switch (rowData.managerStatus) {
    //         case 'PROGRESS':
    //           cell = (
    //             <TPButtonBasic
    //               label="배정중"
    //               size="small"
    //               startIcon={null}
    //               variant="outlined"
    //               disabled={true}
    //             />
    //           );
    //           break;
    //         default:
    //           break;
    //       }
    //     }
    //     return cell;
    //   }
    // },
    {
      code: 'workUserList',
      value: '작업자',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        const { taskId } = rowData;
        let cell: ReactElement | undefined = undefined;
        const clickHandler = (e: React.MouseEvent) => {
          e.stopPropagation();
          navigate(
            `/project/${projectId}/${ModalType.ASSIGNALERT_MODAL}/${Number(
              taskId
            )}`,
            {
              state: { data, rowData }
            }
          );
          // console.log(rowData);
        };

        if (
          Array.isArray(data) &&
          data.length &&
          rowData.taskStatus !== 'NEW'
        ) {
          cell = <TPAvatarGroupNew userList={data} />;
        } else {
          if (rowData.taskStatus === 'STOP') return;
          switch (rowData.managerStatus) {
            case 'PROGRESS':
              cell = (
                <TPButtonBasic
                  label="배정중"
                  size="small"
                  startIcon={null}
                  variant="outlined"
                  disabled={true}
                />
              );
              break;
            default:
              cell = (
                <button className={cssButton} onClick={clickHandler}>
                  배정요청
                </button>
              );
              break;
          }
          // cell = (
          //   <TPButtonBasic
          //     label="배정중"
          //     size="small"
          //     startIcon={null}
          //     variant="outlined"
          //     disabled={true}
          //   />
          // );
        }
        return cell;
      }
    },
    {
      code: 'startDateTime',
      value: '시작일',
      width: 100,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any) => renderDateTime(data, false)
    },
    {
      code: 'endDateTime',
      value: '마감일',
      width: 100,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any, rowData: any) =>
        renderDateTime(data, rowData.taskStatus !== 'DELIVERY_COMPLETE')
    },
    {
      code: 'totalGrossAmount',
      value: '총 분량(Gross)',
      width: 120,
      renderHeader: renderGrossInfo,
      renderRowData: (data: any, row: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        if (row.taskType === 'LQA') {
          return (
            <span>
              {Math.floor(Number(data) / 250)}
              시간 {Number(data) % 250 > 0 ? '30' : '00'}분
            </span>
          );
        } else {
          return <span>{taskAmountSetForm}</span>;
        }
      }
    },
    {
      code: 'totalNetAmount',
      value: '총 분량(Net)',
      width: 120,
      renderHeader: () => renderNetInfo('bottom'),
      renderRowData: (data: any, row: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        if (row.taskType === 'LQA') {
          return (
            <span>
              {Math.floor(Number(data) / 250)}
              시간 {Number(data) % 250 > 0 ? '30' : '00'}분
            </span>
          );
        } else {
          return <span>{taskAmountSetForm}</span>;
        }
      }
    },
    {
      code: 'hopeSupplyDateTime',
      value: '납품일',
      width: 100,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any, rowData: any) =>
        renderDateTime(data, rowData.taskStatus !== 'DELIVERY_COMPLETE')
    },

    ...(isPublished
      ? [
          {
            code: 'file',
            value: '작업파일',
            width: 96,
            renderHeader: 'string',
            renderRowData: (data: any, rowData: any) => {
              // return <TaskFileButton {...rowData} />;
              return <></>;
            }
          }
        ]
      : []),
    {
      code: 'option',
      value: '',
      width: 24,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        const { taskId, taskStatus } = rowData;

        let menusArr: {
          clickMenu: () => void;
          label: string;
          to?: string;
          state?: {};
        }[] = [];

        switch (taskStatus) {
          case 'PROGRESS':
            menusArr = [
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBCOPY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  createOk: false,
                  modalData: {
                    title: '작업등록',
                    text: '',
                    okLabel: '등록하기',
                    cancelLabel: '취소하기',
                    copy: true,
                    taskId: taskId
                  }
                },
                label: '복사하기'
              },
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBMODIFY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  modalData: {
                    text: '',
                    okLabel: '수정하기',
                    cancelLabel: '취소하기'
                  }
                },
                label: '수정하기'
              },
              {
                clickMenu: () => {
                  openAlert({
                    title: '작업 삭제',
                    text: '한 번 삭제된 데이터는 복구가 불가능합니다.',
                    ok: () => deleteTask(taskId)
                  });
                },
                label: '삭제하기'
              }
            ];
            break;
          case 'NEW':
          case 'COMPLETE_ASSIGN':
            menusArr = [
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBCOPY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  createOk: false,
                  modalData: {
                    title: '작업등록',
                    text: '',
                    okLabel: '등록하기',
                    cancelLabel: '취소하기',
                    copy: true,
                    taskId: taskId
                  }
                },
                label: '복사하기'
              },
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBMODIFY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  modalData: {
                    text: '',
                    okLabel: '수정하기',
                    cancelLabel: '취소하기'
                  }
                },
                label: '수정하기'
              },
              {
                clickMenu: () => {
                  openAlert({
                    title: '작업 삭제',
                    text: '한 번 삭제된 데이터는 복구가 불가능합니다.',
                    ok: () => deleteTask(taskId)
                  });
                },
                label: '삭제하기'
              },
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBFILE_PAGE}/${taskId}`,
                state: {
                  display: true,
                  modalData: {
                    text: '',
                    okLabel: '파일관리',
                    cancelLabel: '취소하기',
                    taskId: taskId
                  }
                },
                label: '파일관리'
              }
            ];
            break;
          case 'COMPLETE':
            menusArr = [
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBCOPY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  createOk: false,
                  modalData: {
                    title: '작업등록',
                    text: '',
                    okLabel: '등록하기',
                    cancelLabel: '취소하기',
                    copy: true,
                    taskId: taskId
                  }
                },
                label: '복사하기'
              },
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBMODIFY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  modalData: {
                    text: '',
                    okLabel: '수정하기',
                    cancelLabel: '취소하기'
                  }
                },
                label: '수정하기'
              }
            ];
            break;
          case 'STOP':
            menusArr = [
              {
                clickMenu: () => {},
                to: `/project/${projectId}/${ModalType.JOBCOPY_PAGE}/${taskId}`,
                state: {
                  display: true,
                  createOk: false,
                  modalData: {
                    title: '작업등록',
                    text: '',
                    okLabel: '등록하기',
                    cancelLabel: '취소하기',
                    copy: true,
                    taskId: taskId
                  }
                },
                label: '복사하기'
              }
            ];
            break;
          case 'DELIVERY_COMPLETE':
            if (isPML) {
              menusArr = [
                {
                  clickMenu: () => {},
                  to: `/project/${projectId}/${ModalType.JOBCOPY_PAGE}/${taskId}`,
                  state: {
                    display: true,
                    createOk: false,
                    modalData: {
                      title: '작업등록',
                      text: '',
                      okLabel: '등록하기',
                      cancelLabel: '취소하기',
                      copy: true,
                      taskId: taskId
                    }
                  },
                  label: '복사하기'
                },
                {
                  clickMenu: () => {},
                  to: `/project/${projectId}/${ModalType.JOBMODIFY_PAGE}/${taskId}`,
                  state: {
                    display: true,
                    modalData: {
                      text: '',
                      okLabel: '수정하기',
                      cancelLabel: '취소하기'
                    }
                  },
                  label: '수정하기'
                }
              ];
            } else {
              menusArr = [
                {
                  clickMenu: () => {},
                  to: `/project/${projectId}/${ModalType.JOBCOPY_PAGE}/${taskId}`,
                  state: {
                    display: true,
                    createOk: false,
                    modalData: {
                      title: '작업등록',
                      text: '',
                      okLabel: '등록하기',
                      cancelLabel: '취소하기',
                      copy: true,
                      taskId: taskId
                    }
                  },
                  label: '복사하기'
                }
              ];
            }
            break;
          default:
            break;
        }
        return menusArr.length > 0 ? (
          <TPPopoverMenu
            popoverMenus={menusArr}
            clickPopoverButton={(e: any) => {
              e.stopPropagation();
            }}
          >
            <TPButtonAction icon={<IconDetail />} size="small" />
          </TPPopoverMenu>
        ) : null;
      }
    }
  ] as const;
};

const cssButton = css`
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: transparent;
  color: #9b51e0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.25px;
  text-align: center;
  width: 100%;
  height: 100%;
`;
